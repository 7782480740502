import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex flex-col text-center items-center px-5"
};
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  __name: 'NotesLayout',
  setup(__props) {
    var store = useStore();
    var router = useRouter();
    var note = ref(store.state.Auth.notes);
    function onClickLeft() {
      router.back();
    }
    return (_ctx, _cache) => {
      var _component_van_nav_bar = _resolveComponent("van-nav-bar");
      var _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        "left-text": _ctx.$t('buttons.go_back'),
        title: note.value.date,
        "left-arrow": "",
        onClickLeft: onClickLeft,
        class: "w-full"
      }, null, 8, ["left-text", "title"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view)])], 64);
    };
  }
};