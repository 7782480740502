import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "text-2xl"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_space = _resolveComponent("van-space");
  return _openBlock(), _createBlock(_component_van_space, {
    direction: $props.direction,
    class: "mb-4"
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_renderSlot(_ctx.$slots, "default")])]),
    _: 3
  }, 8, ["direction"]);
}