import '@/plugins/nativescript-webview-interface.js';
import ItserveButton from '@/components/forms/ItserveButton';
export default {
  name: 'AuthorizationButtons',
  components: {
    ItserveButton
  },
  computed: {
    nativeData() {
      return this.$store.state.Auth.nativeData;
    }
  },
  methods: {
    googleSign() {
      this.nativeSendEvent('googleSignin');
    },
    appleSign() {
      this.nativeSendEvent('appleSignin');
    }
  }
};