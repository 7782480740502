import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full h-full"
};
var _hoisted_2 = {
  class: "flex flex-col items-center px-5"
};
import { ref } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  __name: 'CalendarView',
  setup(__props) {
    var store = useStore();
    var router = useRouter();
    var currentDate = new Date(formatter(new Date()));
    var calendar = computed(() => store.state.Auth.calendar);
    var attributes = ref(getAttrs(calendar.value, currentDate));
    var activeDay = ref(store.state.Auth.notes.date);
    function dayClick(day) {
      if (!(new Date(day.id).getTime() > currentDate.getTime())) {
        activeDay.value = day.id;
        var i = doesHaveDay(calendar.value, day.id);
        if (i == -1) {
          calendar.value.push({
            period_date: day.id
          });
        } else {
          calendar.value.splice(i, 1);
        }
        store.dispatch('Auth/setActiveDay', day.id);
      } else {
        activeDay.value = null;
        store.dispatch('Auth/setActiveDay', null);
        store.dispatch('Auth/setNotes', patternNote);
      }
      attributes.value = getAttrs(calendar.value, currentDate);
      store.dispatch('Auth/updateCalendar', calendar.value);
    }
    function getAttrs(arr1, date) {
      var dates = arr1.map(elem => {
        var fillMode = 'solid';
        if (new Date(elem.period_date).getTime() > date.getTime()) {
          fillMode = 'light';
        }
        return {
          highlight: {
            color: 'product-pink',
            fillMode: fillMode
          },
          dates: new Date(elem.period_date)
        };
      });
      return dates;
    }
    function doesHaveDay(arr, date) {
      var flag = -1;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].period_date == date) {
          flag = i;
        }
      }
      return flag;
    }
    function onClickLeft() {
      router.back();
    }
    function onClickRight() {
      router.push({
        name: 'SettingsView'
      });
    }
    function formatter(date) {
      return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
    }
    function addZero(num) {
      return num < 9 ? `0${num}` : num;
    }
    return (_ctx, _cache) => {
      var _component_van_icon = _resolveComponent("van-icon");
      var _component_van_nav_bar = _resolveComponent("van-nav-bar");
      var _component_CalendarComponent = _resolveComponent("CalendarComponent");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        "left-arrow": "",
        "left-text": _ctx.$t('buttons.go_back'),
        onClickLeft: onClickLeft,
        onClickRight: onClickRight
      }, {
        right: _withCtx(() => [_createVNode(_component_van_icon, {
          name: "setting",
          size: "30"
        })]),
        _: 1
      }, 8, ["left-text"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_CalendarComponent, {
        rows: 2,
        attributes: attributes.value,
        currentDate: _unref(currentDate),
        onClick: dayClick
      }, null, 8, ["attributes", "currentDate"])])]);
    };
  }
};