import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = n => (_pushScopeId("data-v-29a3015f"), n = n(), _popScopeId(), n);
var _hoisted_1 = {
  class: "w-full h-full"
};
var _hoisted_2 = {
  class: "flex flex-col items-center px-5"
};
var _hoisted_3 = {
  class: "text-decor text-rotate text-3xl"
};
var _hoisted_4 = {
  class: "text-decor text-rotate text-3xl"
};
import CalendarComponent from '@/components/template/CalendarComponent.vue';
import NotesList from '@/components/template/NotesList.vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  __name: 'HomeView',
  setup(__props) {
    var store = useStore();
    var router = useRouter();
    var currentDate = new Date(formatter(new Date()));
    var calendar = computed(() => store.state.Auth.calendar);
    console.log(calendar.value);
    var notesArr = computed(() => store.state.Auth.notesArr);
    var note = computed(() => store.state.Auth.notes);
    var attributes = ref(getAttrs(calendar.value, notesArr.value, currentDate));
    var activeDay = ref(store.state.Auth.notes.date);
    var patternNote = {
      date: null,
      sexual_act_protected: null,
      sexual_act_unprotected: null,
      orgasm: null,
      pills: null,
      notes: null,
      symptoms: null,
      temperature: null,
      weight: null,
      water: 0,
      mood: null
    };
    function dayClick(day) {
      if (!(new Date(day.id).getTime() > currentDate.getTime())) {
        activeDay.value = day.id;
        var j = doesHaveNote(notesArr.value, day.id);
        if (j == -1) {
          store.dispatch('Auth/setNotes', patternNote);
        } else {
          store.dispatch('Auth/setNotes', notesArr.value[j]);
        }
        store.dispatch('Auth/setActiveDay', day.id);
      } else {
        activeDay.value = null;
        store.dispatch('Auth/setActiveDay', null);
        store.dispatch('Auth/setNotes', patternNote);
      }
      attributes.value = getAttrs(calendar.value, notesArr.value, currentDate);
    }
    function getAttrs(arr1, arr2, date) {
      var dates = arr1.map(elem => {
        var fillMode = 'solid';
        if (new Date(elem.period_date).getTime() > date.getTime()) {
          fillMode = 'light';
        }
        return {
          highlight: {
            color: 'product-pink',
            fillMode: fillMode
          },
          dates: new Date(elem.period_date)
        };
      });
      var redNotes = arr2.map(elem => {
        if (elem.sexual_act_protected || elem.sexual_act_unprotected || elem.symptoms) {
          return {
            dot: {
              style: {
                backgroundColor: 'red'
              }
            },
            dates: [new Date(elem.date)]
          };
        }
      });
      var grayNotes = arr2.map(elem => {
        if (elem.pills || elem.temperature) {
          return {
            dot: {
              style: {
                backgroundColor: 'gray'
              }
            },
            dates: [new Date(elem.date)]
          };
        }
      });
      var blueNotes = arr2.map(elem => {
        if (elem.weight || elem.water) {
          return {
            dot: {
              style: {
                backgroundColor: 'blue'
              }
            },
            dates: [new Date(elem.date)]
          };
        }
      });
      var yellowNotes = arr2.map(elem => {
        if (elem.notes || elem.mood) {
          return {
            dot: {
              style: {
                backgroundColor: 'yellow'
              }
            },
            dates: [new Date(elem.date)]
          };
        }
      });
      return [...dates, ...redNotes, ...grayNotes, ...blueNotes, ...yellowNotes];
    }
    function doesHaveNote(arr, date) {
      var flag = -1;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].date == date) {
          flag = i;
        }
      }
      return flag;
    }
    function onClickActiveDay() {
      if (activeDay.value) {
        router.push({
          name: 'NotesView'
        });
      }
    }
    function onClickRight() {
      router.push({
        name: 'SettingsView'
      });
    }
    function formatter(date) {
      return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
    }
    function addZero(num) {
      return num < 9 ? `0${num}` : num;
    }
    return (_ctx, _cache) => {
      var _component_van_icon = _resolveComponent("van-icon");
      var _component_van_nav_bar = _resolveComponent("van-nav-bar");
      var _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        onClickRight: onClickRight
      }, {
        right: _withCtx(() => [_createVNode(_component_van_icon, {
          name: "setting",
          size: "30"
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_2, [_createVNode(CalendarComponent, {
        attributes: attributes.value,
        currentDate: _unref(currentDate),
        onClick: dayClick
      }, null, 8, ["attributes", "currentDate"]), _createVNode(_component_van_button, {
        icon: "edit",
        round: "",
        type: "primary",
        class: "mb-2",
        to: {
          name: 'CalendarView'
        }
      }, {
        default: _withCtx(() => [_createTextVNode("Change days")]),
        _: 1
      }), _createElementVNode("div", {
        class: "w-48 h-48 bg-yellow-300 flex flex-col items-center justify-center",
        onClick: onClickActiveDay
      }, [_createElementVNode("p", _hoisted_3, _toDisplayString(activeDay.value), 1), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('buttons.add_note')), 1)]), _createVNode(NotesList, {
        note: note.value
      }, null, 8, ["note"])])]);
    };
  }
};