import es from './es.json';
import en from './en.json';
import az from './az.json';
import bl from './bl.json';
import cs from './cs.json';
import de from './de.json';
import fr from './fr.json';
import it from './it.json';
import kz from './kz.json';
import lt from './lt.json';
import pl from './pl.json';
import ro from './ro.json';
import ru from './ru.json';
import tr from './tr.json';
import ua from './ua.json';
import uz from './uz.json';

export const locales = {
	en,
	es,
	az,
	bl,
	cs,
	de,
	fr,
	it,
	kz,
	lt,
	pl,
	ro,
	ru,
	tr,
	ua,
	uz,
};
