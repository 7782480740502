import api from '@/plugins/api/EntryPoint';
import axios from 'axios';

export default {
	/**
	 * Retrieve accounst from server before return account prepare them for displaying
	 * few resions to prepare data - right variable names, format words
	 * @param callBackFn
	 * @returns {Promise<*>}
	 */
	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	},
	async refreshToken(cb) {
		return await api.get(`auth/refresh-token`, response => {
			return cb(response);
		});
	},
	async nativeAuth(nativeData) {
		return await axios
			.post(process.env.VUE_APP_API_ENDPOINT_URL + 'userAuth', nativeData)
			.catch(error => {
				console.log(error);
			});
	},
	googleAuth(nativeData) {
		return axios
			.post(process.env.VUE_APP_API_ENDPOINT_URL + 'googleAuth', nativeData)
			.catch(error => {
				console.log(error);
			});
	},
	deleteAccountByNumber(accountNumber, callBackFn) {
		return api.post(
			'settings/delete-account',
			{ ls: accountNumber },
			callBackFn
		);
	},
	deleteUser(callBackFn) {
		return api.post('settings/delete-user', callBackFn);
	},
	getQuestions(callBackFn, language) {
		return api.get(`question/${language}`, callBackFn);
	},
	getAnswers(callBackFn) {
		return api.get(`answer/show`, callBackFn);
	},
	setAnswers(answers) {
		return api.post(`answer`, answers);
	},
	updateAnswers(answers) {
		return api.put(`answer/update`, answers);
	},
	getCalendar(callBackFn) {
		return api.get(`calendar/show`, callBackFn);
	},
	updateCalendar(calendar) {
		return api.put(`calendar/update`, calendar);
	},
	getNotesArr(callBackFn) {
		return api.get('user-note/show', callBackFn);
	},
	setNotesArr(notesArr) {
		return api.post('user-note', notesArr);
	},
};
