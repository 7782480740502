import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full h-full"
};
import { useStore } from 'vuex';
import QuestionsComponent from '@/components/template/QuestionsComponent.vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'QuestionsView',
  setup(__props) {
    var currentDate = new Date();
    var store = useStore();
    var router = useRouter();
    var questions = computed(() => store.state.Auth.questions);
    var answers = computed(() => store.state.Auth.answers);
    var entry = computed(() => store.state.Settings.entry);

    // store.dispatch('Auth/getAnswers');

    function onClickLeft() {
      return router.back();
    }
    function setAnswers(answers_model) {
      !entry ? store.dispatch('Auth/updateAnswers', answers_model) : store.dispatch('Auth/setAnswers', answers_model);
      router.push({
        name: 'HomeView'
      });
    }
    function formatter(date) {
      return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
    }
    function addZero(num) {
      return num < 9 ? `0${num}` : num;
    }
    return (_ctx, _cache) => {
      var _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [!entry.value ? (_openBlock(), _createBlock(_component_van_nav_bar, {
        key: 0,
        "left-text": _ctx.$t('buttons.go_back'),
        onClickLeft: onClickLeft,
        "left-arrow": "",
        class: "bg-white"
      }, null, 8, ["left-text"])) : _createCommentVNode("", true), _createVNode(QuestionsComponent, {
        class: "pt-8",
        questions: questions.value,
        answers: answers.value,
        currentDate: _unref(currentDate),
        entry: entry.value,
        onOnSubmit: setAnswers
      }, null, 8, ["questions", "answers", "currentDate", "entry"])]);
    };
  }
};