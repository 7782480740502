import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex items-center justify-center h-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_loading = _resolveComponent("van-loading");
  var _component_van_overlay = _resolveComponent("van-overlay");
  return _openBlock(), _createBlock(_component_van_overlay, {
    show: $props.show
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_loading, {
      color: "#E52C4B",
      size: "60",
      type: "spinner"
    })])]),
    _: 1
  }, 8, ["show"]);
}