import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../assets/happy.png';
import _imports_1 from '../../assets/smile.png';
import _imports_2 from '../../assets/straight.png';
var _hoisted_1 = {
  class: "w-full flex items-center justify-around"
};
var _hoisted_2 = {
  class: "text-decor text-rotate text-5xl"
};
var _hoisted_3 = {
  class: "w-full"
};
var _hoisted_4 = {
  class: "w-full flex flex-col"
};
var _hoisted_5 = {
  class: "flex flex-1 flex-col items-center"
};
var _hoisted_6 = {
  class: "flex flex-1 flex-col items-center"
};
var _hoisted_7 = {
  class: "flex flex-1 flex-col items-center"
};
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  __name: 'MoodView',
  setup(__props) {
    var store = useStore();
    var router = useRouter();
    var mood = ref(store.state.Auth.notes.mood);
    function setMood(num) {
      console.log(num);
      mood.value = mood.value == num ? null : num;
    }
    function onClick() {
      store.dispatch('Auth/setMood', mood.value);
      router.push({
        name: 'NotesView'
      });
    }
    return (_ctx, _cache) => {
      var _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('routes.MoodView')), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
        src: _imports_0,
        alt: "men-wemen_icon",
        class: _normalizeClass(["h-28 w-28 m-4 border-2 border-transparent rounded-md p-1 transition-colors hover:border-red-600", {
          'border-red-600': mood.value == 2
        }]),
        onClick: _cache[0] || (_cache[0] = $event => setMood(2))
      }, null, 2)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
        src: _imports_1,
        alt: "men-wemen_icon",
        class: _normalizeClass([{
          'border-red-600': mood.value == 1
        }, "h-28 w-28 m-4 border-2 border-transparent rounded-md p-1 transition-colors hover:border-red-600"]),
        onClick: _cache[1] || (_cache[1] = $event => setMood(1))
      }, null, 2)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
        src: _imports_2,
        alt: "men-wemen_icon",
        class: _normalizeClass([{
          'border-red-600': mood.value == 0
        }, "h-28 w-28 m-4 border-2 border-transparent rounded-md p-1 transition-colors hover:border-red-600"]),
        onClick: _cache[2] || (_cache[2] = $event => setMood(0))
      }, null, 2)])])]), _createVNode(_component_van_button, {
        icon: "success",
        round: "",
        type: "success",
        class: "w-12 h-12 mt-2",
        onClick: onClick
      })], 64);
    };
  }
};