import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import BasicIframe from '@/components/template/BasicIframe.vue';
export default {
  __name: 'PolicyView',
  setup(__props) {
    var url = process.env.VUE_APP_POLICY;
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(BasicIframe, {
        url: _unref(url)
      }, null, 8, ["url"]);
    };
  }
};