import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "my-6 text-4xl font-semibold text-white text-decor bg-pink-600 py-2 px-4 rounded-md"
};
var _hoisted_2 = {
  class: "flex items-center"
};
var _hoisted_3 = {
  class: "align-middle mr-2"
};
var _hoisted_4 = {
  class: "flex items-center"
};
var _hoisted_5 = {
  class: "align-middle mr-2"
};
var _hoisted_6 = {
  class: "flex items-center"
};
var _hoisted_7 = {
  class: "align-middle mr-2"
};
var _hoisted_8 = {
  class: "flex items-center"
};
var _hoisted_9 = {
  class: "align-middle mr-2"
};
var _hoisted_10 = {
  class: "flex items-center"
};
var _hoisted_11 = {
  class: "align-middle mr-2"
};
var _hoisted_12 = {
  class: "flex items-center"
};
var _hoisted_13 = {
  class: "align-middle mr-2"
};
var _hoisted_14 = {
  class: "flex items-center"
};
var _hoisted_15 = {
  class: "align-middle mr-2"
};
var _hoisted_16 = {
  class: "flex items-center"
};
var _hoisted_17 = {
  class: "align-middle mr-2"
};
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  __name: 'NotesView',
  setup(__props) {
    var store = useStore();
    var router = useRouter();
    var note = ref(store.state.Auth.notes);
    var notesArr = ref(store.state.Auth.notesArr);
    var j = doesNotesHaveDay(notesArr.value, note.value.date);
    function onClick() {
      if (j >= 0) {
        notesArr.value.splice(j, 1, note.value);
      } else {
        notesArr.value.push(note.value);
      }
      store.dispatch('Auth/setNotesArr', notesArr.value);
      store.dispatch('Auth/setNotes', {
        date: null,
        sexual_act_protected: false,
        sexual_act_unprotected: false,
        orgasm: null,
        pills: null,
        weight: null,
        temperature: null,
        mood: null,
        notes: null,
        water: null,
        symptoms: null
      });
      router.push({
        name: 'HomeView'
      });
    }
    function doesNotesHaveDay(arr, date) {
      var flag = -1;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].date == date) {
          flag = i;
        }
      }
      return flag;
    }
    return (_ctx, _cache) => {
      var _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
      var _component_van_cell = _resolveComponent("van-cell");
      var _component_van_button = _resolveComponent("van-button");
      var _component_van_cell_group = _resolveComponent("van-cell-group");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('routes.NotesView')), 1), _createVNode(_component_van_cell_group, {
        inset: "",
        class: "w-full"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell, {
          "is-link": "",
          to: {
            name: 'SexualActView'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('page_links.SexualActView')), 1), _createVNode(_component_font_awesome_icon, {
            icon: "fa-solid fa-heart",
            class: "text-red-600"
          })])]),
          _: 1
        }), _createVNode(_component_van_cell, {
          "is-link": "",
          to: {
            name: 'PillsView'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('page_links.PillsView')), 1), _createVNode(_component_font_awesome_icon, {
            icon: "fa-solid fa-pills",
            class: "text-gray-500"
          })])]),
          _: 1
        }), _createVNode(_component_van_cell, {
          "is-link": "",
          to: {
            name: 'WeightView'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('page_links.WeightView')), 1), _createVNode(_component_font_awesome_icon, {
            icon: "fa-solid fa-weight-scale",
            class: "text-blue-600"
          })])]),
          _: 1
        }), _createVNode(_component_van_cell, {
          "is-link": "",
          to: {
            name: 'TemperatureView'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", _hoisted_8, [_createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('page_links.TemperatureView')), 1), _createVNode(_component_font_awesome_icon, {
            icon: "fa-solid fa-thermometer",
            class: "text-gray-500"
          })])]),
          _: 1
        }), _createVNode(_component_van_cell, {
          "is-link": "",
          to: {
            name: 'MoodView'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('page_links.MoodView')), 1), _createVNode(_component_font_awesome_icon, {
            icon: "fa-solid fa-face-smile",
            class: "text-yellow-500"
          })])]),
          _: 1
        }), _createVNode(_component_van_cell, {
          "is-link": "",
          to: {
            name: 'NoteView'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", _hoisted_12, [_createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('page_links.NoteView')), 1), _createVNode(_component_font_awesome_icon, {
            icon: "fa-solid fa-note-sticky",
            class: "text-yellow-500"
          })])]),
          _: 1
        }), _createVNode(_component_van_cell, {
          "is-link": "",
          to: {
            name: 'WaterView'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", _hoisted_14, [_createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('page_links.WaterView')), 1), _createVNode(_component_font_awesome_icon, {
            icon: "fa-solid fa-glass-water",
            class: "text-blue-600"
          })])]),
          _: 1
        }), _createVNode(_component_van_cell, {
          "is-link": "",
          to: {
            name: 'SymptomsView'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", _hoisted_16, [_createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('page_links.SymptomsView')), 1), _createVNode(_component_font_awesome_icon, {
            icon: "fa-solid fa-kit-medical",
            class: "text-red-600"
          })])]),
          _: 1
        }), _createVNode(_component_van_button, {
          icon: "success",
          round: "",
          type: "success",
          class: "my-2",
          title: "Confirm",
          onClick: onClick
        })]),
        _: 1
      })], 64);
    };
  }
};