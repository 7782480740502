import FeedBack from '@/plugins/api/factories/FeedBack';

export const state = {
	feedback: [],
	message: null,
	ticket: {
		title: null,
		description: null,
		supportType: null,
		ls: null,
	},
};

export const mutations = {
	SET_FEEDBACK(state, feedback) {
		state.feedback = feedback;
	},
	RESET_TICKET(state) {
		state.ticket = {
			title: null,
			description: null,
		};
	},
	UPDATE_TITLE(state, title) {
		state.ticket.title = title;
	},
	UPDATE_DESCRIPTION(state, description) {
		state.ticket.description = description;
	},
	SET_SUPPORT_TYPE(state, type) {
		state.ticket.supportType = type;
	},
	SET_ACCOUNT(state, account) {
		state.ticket.ls = account;
	},
};

export const actions = {
	getFeedBack({ commit }) {
		FeedBack.getFeedback(response => {
			commit('SET_FEEDBACK', response.feedback);
		});
	},
	createTicket({ commit, state }, callBackFn) {
		FeedBack.createTicket(state.ticket, response => {
			commit('RESET_TICKET');
			callBackFn(response.message);
		});
	},
};
