import { createRouter, createWebHistory } from 'vue-router';
import '@/plugins/nativescript-webview-interface.js';
import TermsOfUseView from '../views/authorization/TermsOfUseView.vue';
import AgreementLayout from '../components/template/AgreementLayout.vue';
import OfferView from '../views/authorization/OfferView.vue';
import PolicyView from '../views/authorization/PolicyView.vue';
import AuthorizationView from '../views/authorization/AuthorizationView.vue';
import DefaultLayout from '../components/template/DefaultLayout.vue';
import HomeView from '../views/HomeView.vue';
import CalendarView from '../views/CalendarView.vue';
import NotesLayout from '../components/template/NotesLayout.vue';
import NotesView from '../views/notes/NotesView.vue';
import MoodView from '../views/notes/MoodView.vue';
import NoteView from '../views/notes/NoteView.vue';
import PillsView from '../views/notes/PillsView.vue';
import SexualActView from '../views/notes/SexualAct.vue';
import SymptomsView from '../views/notes/SymptomsView.vue';
import TemperatureView from '../views/notes/TemperatureView.vue';
import WaterView from '../views/notes/WaterView.vue';
import WeightView from '../views/notes/WeightView.vue';
import QuestionsView from '../views/QuestionsView.vue';
import SettingsView from '../views/settings/SettingsView.vue';
import PlugView from '../views/PlugView.vue';
import store from '@/store';
import { computed } from 'vue';

const routes = [
	{
		path: '/terms-of-use',
		name: 'TermsOfUseView',
		component: TermsOfUseView,
		meta: {
			isGuest: true,
		},
	},
	{
		path: '/agreement',
		redirect: '/offer',
		component: AgreementLayout,
		children: [
			{
				path: '/offer',
				name: 'OfferView',
				component: OfferView,
			},
			{
				path: '/policy',
				name: 'PolicyView',
				component: PolicyView,
			},
		],
	},
	{
		path: '/auth',
		name: 'AuthorizationView',
		component: AuthorizationView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/',
		redirect: '/home',
		component: DefaultLayout,
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: '/home',
				name: 'HomeView',
				component: HomeView,
			},
			{
				path: '/questions',
				name: 'QuestionsView',
				component: QuestionsView,
			},
			{
				path: '/calendar',
				name: 'CalendarView',
				component: CalendarView,
			},
			{
				path: '/notes-layout',
				redirect: '/notes',
				component: NotesLayout,
				children: [
					{
						path: '/notes',
						name: 'NotesView',
						component: NotesView,
					},
					{
						path: '/mood',
						name: 'MoodView',
						component: MoodView,
					},
					{
						path: '/note',
						name: 'NoteView',
						component: NoteView,
					},
					{
						path: '/pills',
						name: 'PillsView',
						component: PillsView,
					},
					{
						path: '/sexual-act',
						name: 'SexualActView',
						component: SexualActView,
					},
					{
						path: '/symptoms',
						name: 'SymptomsView',
						component: SymptomsView,
					},
					{
						path: '/temperature',
						name: 'TemperatureView',
						component: TemperatureView,
					},
					{
						path: '/water',
						name: 'WaterView',
						component: WaterView,
					},
					{
						path: '/weight',
						name: 'WeightView',
						component: WeightView,
					},
				],
			},
		],
	},
	{
		path: '/settings',
		name: 'SettingsView',
		component: SettingsView,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'PlugView',
		component: PlugView,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	const authToken = computed(() => store.state.Auth.authToken);
	const agreement = computed(() => store.state.Settings.agreement);
	const guest = computed(() => store.state.Auth.guest);
	const entry = computed(() => store.state.Settings.entry);

	if (
		to.meta.requiresAuth &&
		Boolean(authToken.value) &&
		!agreement.value &&
		!guest.value
	) {
		console.log('terms path');
		console.log(
			'authToken: ',
			authToken.value,
			'; agreement: ',
			agreement.value,
			'; guest: ',
			guest.value,
			'; entry: ',
			entry.value
		);
		next({ name: 'TermsOfUseView' });
	} else if (
		to.meta.isGuest &&
		Boolean(authToken.value) &&
		agreement.value &&
		!guest.value
	) {
		console.log('auth path');
		console.log(
			'authToken: ',
			authToken.value,
			'; agreement: ',
			agreement.value,
			'; guest: ',
			guest.value,
			'; entry: ',
			entry.value
		);
		next({ name: 'AuthorizationView' });
	} else if (
		to.meta.isGuest &&
		Boolean(authToken.value) &&
		agreement.value &&
		guest.value &&
		!entry.value
	) {
		console.log('home path');
		store.dispatch('Auth/getCalendar');
		store.dispatch('Auth/getNotesArr');
		store.dispatch('Auth/getAnswers');
		next({ name: 'HomeView' });
		console.log(
			'authToken: ',
			authToken.value,
			'; agreement: ',
			agreement.value,
			'; guest: ',
			guest.value,
			'; entry: ',
			entry.value
		);
	}
	//  else if (
	// 	to.meta.isGuest &&
	// 	Boolean(authToken.value) &&
	// 	agreement.value &&
	// 	guest.value &&
	// 	entry.value
	// ) {
	// 	console.log('questions path');
	// 	next({ name: 'QuestionsView' });
	// 	console.log(
	// 		'authToken: ',
	// 		authToken.value,
	// 		'; agreement: ',
	// 		agreement.value,
	// 		'; guest: ',
	// 		guest.value,
	// 		'; entry: ',
	// 		entry.value
	// 	);
	// }
	else {
		console.log('else path');
		console.log(
			'authToken: ',
			authToken.value,
			'; agreement: ',
			agreement.value,
			'; guest: ',
			guest.value,
			'; entry: ',
			entry.value
		);
		next();
	}
});

export default router;
