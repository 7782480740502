import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = n => (_pushScopeId("data-v-4549a578"), n = n(), _popScopeId(), n);
var _hoisted_1 = {
  class: "h-full bg-white w-full px-4"
};
import ItserveButton from '@/components/forms/ItserveButton.vue';
import appBridge from '@/mixins/app-bridge';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'QuestionsComponent',
  props: {
    to: String,
    questions: Object,
    answers: Object,
    entry: Number,
    currentDate: Object
  },
  emits: ['onSubmit'],
  setup(__props, {
    emit: emits
  }) {
    var {
      questions,
      answers,
      entry,
      currentDate
    } = __props;
    var store = useStore();
    var locale = computed(() => store.state.Auth.nativeData.lang);
    var answers_model = ref(answers);
    watch(locale, (newLocale, oldLocale) => {
      store.dispatch('Auth/getQuestions', newLocale);
    });
    var currentDateBirth = ref(answers.birth_date.split('-'));
    var currentDatePeriod = ref(answers.last_period_date.split('-'));
    var selectedValueCycle = ref([answers.duration_cycle_last]);
    var selectedValuePeriod = ref([answers.duration_period_last]);
    var showPickerCycle = ref(false);
    var showPickerPeriod = ref(false);
    var showPickerDate = ref(false);
    var showPickerBirthDate = ref(false);
    var minDate = new Date(currentDate.getFullYear() - 70, 0, 1);
    var maxDate = currentDate;
    var columns = (() => {
      var arr = [];
      for (var i = 1; i <= 31; i++) {
        arr.push({
          text: i,
          value: i
        });
      }
      return arr;
    })();
    appBridge.methods.nativeSendEvent('hideKeyboard', true);
    function onConfirmCycle({
      selectedOptions
    }) {
      answers_model.value.duration_cycle_last = selectedOptions[0]?.text;
      showPickerCycle.value = false;
    }
    function onConfirmPeriod({
      selectedOptions
    }) {
      answers_model.value.duration_period_last = selectedOptions[0]?.text;
      showPickerPeriod.value = false;
    }
    function onConfirmDate({
      selectedValues
    }) {
      answers_model.value.last_period_date = selectedValues.join('-');
      showPickerDate.value = false;
    }
    function onConfirmBirthDate({
      selectedValues
    }) {
      answers_model.value.birth_date = selectedValues.join('-');
      showPickerBirthDate.value = false;
    }
    return (_ctx, _cache) => {
      var _component_van_field = _resolveComponent("van-field");
      var _component_van_date_picker = _resolveComponent("van-date-picker");
      var _component_van_popup = _resolveComponent("van-popup");
      var _component_van_picker = _resolveComponent("van-picker");
      var _component_van_cell_group = _resolveComponent("van-cell-group");
      var _component_van_form = _resolveComponent("van-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_form, {
        onSubmit: _cache[21] || (_cache[21] = $event => emits('onSubmit', answers_model.value))
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, {
          inset: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            name: "name",
            "label-align": "top",
            placeholder: _ctx.$t('placeholders.entryName'),
            label: __props.questions.name,
            modelValue: answers_model.value.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => answers_model.value.name = $event),
            rules: [{
              required: true,
              message: _ctx.$t('placeholders.requiredName')
            }]
          }, null, 8, ["placeholder", "label", "modelValue", "rules"]), _createVNode(_component_van_field, {
            name: "birth_date",
            "label-align": "top",
            placeholder: _ctx.$t('placeholders.selectDate'),
            label: __props.questions.birth_date,
            modelValue: answers_model.value.birth_date,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => answers_model.value.birth_date = $event),
            "is-link": "",
            readonly: "",
            rules: [{
              required: true,
              message: _ctx.$t('placeholders.required_b_day')
            }],
            onClick: _cache[2] || (_cache[2] = $event => showPickerBirthDate.value = true)
          }, null, 8, ["placeholder", "label", "modelValue", "rules"]), _createVNode(_component_van_popup, {
            show: showPickerBirthDate.value,
            "onUpdate:show": _cache[5] || (_cache[5] = $event => showPickerBirthDate.value = $event),
            position: "bottom"
          }, {
            default: _withCtx(() => [_createVNode(_component_van_date_picker, {
              "min-date": _unref(minDate),
              "max-date": _unref(maxDate),
              modelValue: currentDateBirth.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => currentDateBirth.value = $event),
              onConfirm: onConfirmBirthDate,
              onCancel: _cache[4] || (_cache[4] = $event => showPickerBirthDate.value = false)
            }, null, 8, ["min-date", "max-date", "modelValue"])]),
            _: 1
          }, 8, ["show"]), _createVNode(_component_van_field, {
            modelValue: answers_model.value.duration_cycle_last,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => answers_model.value.duration_cycle_last = $event),
            name: "duration_cycle_last",
            label: __props.questions.duration_cycle_last,
            "label-align": "top",
            placeholder: _ctx.$t('placeholders.selectNum'),
            rules: [{
              required: true,
              message: _ctx.$t('placeholders.required_cycle')
            }],
            readonly: "",
            "is-link": "",
            onClick: _cache[7] || (_cache[7] = $event => showPickerCycle.value = true)
          }, null, 8, ["modelValue", "label", "placeholder", "rules"]), _createVNode(_component_van_popup, {
            show: showPickerCycle.value,
            "onUpdate:show": _cache[10] || (_cache[10] = $event => showPickerCycle.value = $event),
            position: "bottom"
          }, {
            default: _withCtx(() => [_createVNode(_component_van_picker, {
              modelValue: selectedValueCycle.value,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => selectedValueCycle.value = $event),
              columns: _unref(columns),
              onConfirm: onConfirmCycle,
              onCancel: _cache[9] || (_cache[9] = $event => showPickerCycle.value = false)
            }, null, 8, ["modelValue", "columns"])]),
            _: 1
          }, 8, ["show"]), _createVNode(_component_van_field, {
            modelValue: answers_model.value.duration_period_last,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => answers_model.value.duration_period_last = $event),
            name: "duration_period_last",
            label: __props.questions.duration_period_last,
            "label-align": "top",
            placeholder: _ctx.$t('placeholders.selectNum'),
            rules: [{
              required: true,
              message: _ctx.$t('placeholders.required_period')
            }],
            readonly: "",
            "is-link": "",
            onClick: _cache[12] || (_cache[12] = $event => showPickerPeriod.value = true)
          }, null, 8, ["modelValue", "label", "placeholder", "rules"]), _createVNode(_component_van_popup, {
            show: showPickerPeriod.value,
            "onUpdate:show": _cache[15] || (_cache[15] = $event => showPickerPeriod.value = $event),
            position: "bottom"
          }, {
            default: _withCtx(() => [_createVNode(_component_van_picker, {
              modelValue: selectedValuePeriod.value,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => selectedValuePeriod.value = $event),
              columns: _unref(columns),
              onConfirm: onConfirmPeriod,
              onCancel: _cache[14] || (_cache[14] = $event => showPickerPeriod.value = false)
            }, null, 8, ["modelValue", "columns"])]),
            _: 1
          }, 8, ["show"]), __props.entry ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_createVNode(_component_van_field, {
            modelValue: answers_model.value.last_period_date,
            "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => answers_model.value.last_period_date = $event),
            name: "last_period_date",
            label: __props.questions.last_period_date,
            "label-align": "top",
            placeholder: _ctx.$t('placeholders.selectDate'),
            rules: [{
              required: true,
              message: _ctx.$t('placeholders.required_periodDate')
            }],
            readonly: "",
            "is-link": "",
            onClick: _cache[17] || (_cache[17] = $event => showPickerDate.value = true)
          }, null, 8, ["modelValue", "label", "placeholder", "rules"]), _createVNode(_component_van_popup, {
            show: showPickerDate.value,
            "onUpdate:show": _cache[20] || (_cache[20] = $event => showPickerDate.value = $event),
            position: "bottom"
          }, {
            default: _withCtx(() => [_createVNode(_component_van_date_picker, {
              modelValue: currentDatePeriod.value,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => currentDatePeriod.value = $event),
              "min-date": _unref(minDate),
              "max-date": _unref(maxDate),
              onConfirm: onConfirmDate,
              onCancel: _cache[19] || (_cache[19] = $event => showPickerDate.value = false)
            }, null, 8, ["modelValue", "min-date", "max-date"])]),
            _: 1
          }, 8, ["show"])], 64)) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(ItserveButton, {
          value: _ctx.$t('buttons.save'),
          type: "submit",
          "native-type": "submit",
          size: "large",
          class: "mt-4"
        }, null, 8, ["value"])]),
        _: 1
      })]);
    };
  }
};