import api from "@/plugins/api/EntryPoint";

export default {
  async getPaymentUrl(params, callBackFn) {
    return await api.post(`easy-pay/payment`, params, callBackFn);
  },
  async getEasyPayCardsList(callBackFn) {
    await api.post(`easy-pay/card-list`, null, callBackFn);
  },
  async getEasyPaySaveCardsPage(callBackFn) {
    await api.post(`easy-pay/3d-secure-url-alt`, {}, callBackFn);
  },
  async deleteCard(params, callBackFn) {
    console.log("DELETE CARD: " + JSON.stringify(params));
    return await api.post(`easy-pay/delete-card`, params, callBackFn);
  },
};
