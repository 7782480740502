import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "px-4 h-full flex flex-col items-center py-4"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  class: "text-xl font-semibold"
};
var _hoisted_4 = {
  class: "text-l font-semibold"
};
var _hoisted_5 = {
  class: "bg-cloud bg-no-repeat bg-clip-border bg-origin-border bg-center bg-contain flex-1 flex flex-col items-center justify-center text-center font-medium"
};
var _hoisted_6 = {
  class: "md:max-w-xl text-sm mb-4 px-7"
};
import appBridge from '@/mixins/app-bridge';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'TermsOfUseView',
  setup(__props) {
    var store = useStore();
    var router = useRouter();
    var nativeData = computed(() => store.state.Auth.nativeData);
    function confirm() {
      console.log();
      appBridge.methods.nativeSendEvent('confirmAgreenment', true);
      store.dispatch('Auth/changeNativeDataAgreement', true);
      store.dispatch('Settings/setAgreement', true);
    }
    return (_ctx, _cache) => {
      var _component_router_link = _resolveComponent("router-link");
      var _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        src: require('@/components/icons/logo-icon.png'),
        class: "w-36 h-auto md:w-52"
      }, null, 8, _hoisted_2), _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('product.name')), 1), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('product.description')), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('terms_of_use.description')), 1), _createVNode(_component_router_link, {
        class: "cursor-pointer transition-colors hover:text-product-color-pink-600 text-sm mb-2",
        to: {
          name: 'OfferView'
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('terms_of_use.offer')), 1)]),
        _: 1
      }), _createVNode(_component_router_link, {
        class: "cursor-pointer transition-colors hover:text-product-color-pink-600 text-sm",
        to: {
          name: 'PolicyView'
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('terms_of_use.policy')), 1)]),
        _: 1
      })]), _createVNode(_component_van_button, {
        type: "primary",
        round: "",
        size: "large",
        color: "linear-gradient(to right, #F4F474, #E52C4B)",
        to: {
          name: 'AuthorizationView'
        },
        onClick: confirm
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('buttons.accept')), 1)]),
        _: 1
      })]);
    };
  }
};