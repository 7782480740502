import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "bg-white"
};
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  __name: 'AgreementLayout',
  setup(__props) {
    var store = useStore();
    var router = useRouter();
    var entry = computed(() => store.state.Settings.entry);
    function onClickLeft() {
      return entry ? router.push({
        name: 'TermsOfUseView'
      }) : router.push('SettingsView');
    }
    return (_ctx, _cache) => {
      var _component_van_nav_bar = _resolveComponent("van-nav-bar");
      var _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        "left-text": _ctx.$t('buttons.go_back'),
        onClickLeft: onClickLeft,
        "left-arrow": ""
      }, null, 8, ["left-text"]), _createVNode(_component_router_view)]);
    };
  }
};