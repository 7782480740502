import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import AuthorizationButtons from '@/components/template/AuthorizationButtons.vue';
import CheckBoxIOS from '@/components/content/CheckBoxIOS.vue';
import ItserveLanguagePicker from '@/components/forms/ItserveLanguagePicker.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'SettingsView',
  setup(__props) {
    var store = useStore();
    var router = useRouter();
    var theme = computed(() => store.state.Auth.nativeData.theme_mode);
    function onClickLeft() {
      router.back();
    }
    return (_ctx, _cache) => {
      var _component_van_nav_bar = _resolveComponent("van-nav-bar");
      var _component_van_cell = _resolveComponent("van-cell");
      var _component_van_cell_group = _resolveComponent("van-cell-group");
      var _component_van_col = _resolveComponent("van-col");
      var _component_van_row = _resolveComponent("van-row");
      return _openBlock(), _createBlock(_component_van_row, {
        justify: "center",
        class: "h-full bg-white"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_col, {
          span: "24",
          class: "flex flex-col"
        }, {
          default: _withCtx(() => [_createVNode(_component_van_nav_bar, {
            "left-text": _ctx.$t('buttons.go_back'),
            onClickLeft: onClickLeft,
            "left-arrow": ""
          }, null, 8, ["left-text"]), _createVNode(_component_van_cell_group, {
            class: "w-full pt-4"
          }, {
            default: _withCtx(() => [_createVNode(_component_van_cell, null, {
              default: _withCtx(() => [_createVNode(AuthorizationButtons)]),
              _: 1
            }), theme.value ? (_openBlock(), _createBlock(CheckBoxIOS, {
              key: 0
            })) : _createCommentVNode("", true), _createVNode(ItserveLanguagePicker), _createVNode(_component_van_cell, {
              "is-link": "",
              title: _ctx.$t('settings.questions'),
              to: {
                name: 'QuestionsView'
              }
            }, null, 8, ["title"]), _createVNode(_component_van_cell, {
              "is-link": "",
              title: _ctx.$t('terms_of_use.offer'),
              to: {
                name: 'OfferView'
              }
            }, null, 8, ["title"]), _createVNode(_component_van_cell, {
              "is-link": "",
              title: _ctx.$t('terms_of_use.policy'),
              to: {
                name: 'PolicyView'
              }
            }, null, 8, ["title"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};