import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "md:max-w-xl"
};
import IconLogoWmn from '@/components/icons/IconLogoWmn.vue';
export default {
  __name: 'PlugView',
  setup(__props) {
    return (_ctx, _cache) => {
      var _component_van_grid_item = _resolveComponent("van-grid-item");
      var _component_van_grid = _resolveComponent("van-grid");
      return _openBlock(), _createBlock(_component_van_grid, {
        "column-num": 1,
        border: false,
        center: true,
        class: "text-center"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_grid_item, null, {
          default: _withCtx(() => [_createVNode(IconLogoWmn, {
            class: "w-32 h-auto md:w-52"
          })]),
          _: 1
        }), _createVNode(_component_van_grid_item, null, {
          default: _withCtx(() => [_createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('product.slogan')), 1)]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};