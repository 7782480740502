import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'CalendarComponent',
  props: {
    rows: Number,
    attributes: Array,
    currentDate: Object
  },
  emits: ['click'],
  setup(__props, {
    emit
  }) {
    var {
      rows,
      attributes,
      currentDate
    } = __props;
    var store = useStore();
    var locale = computed(() => store.state.Auth.nativeData.lang);
    var disabledDates = ref([{
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1),
      end: null
    }]);
    function dayclick(day) {
      return emit('click', day);
    }
    return (_ctx, _cache) => {
      var _component_VCalendar = _resolveComponent("VCalendar");
      return _openBlock(), _createBlock(_component_VCalendar, {
        locale: locale.value,
        attributes: __props.attributes,
        "disabled-dates": disabledDates.value,
        rows: __props.rows,
        borderless: "",
        expanded: "",
        "is-dark": "system",
        class: "mb-3 my-class",
        onDayclick: dayclick
      }, null, 8, ["locale", "attributes", "disabled-dates", "rows"]);
    };
  }
};