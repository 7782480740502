import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0
};
import { onBeforeMount, ref, watch } from 'vue';
import appBridge from './mixins/app-bridge';
import goBack from './mixins/go-back';
import ItserveLoader from '@/components/content/ItserveLoader';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'App',
  setup(__props) {
    var store = useStore();
    var route = useRoute();
    var router = useRouter();
    var i18n = useI18n();
    var nativeData = computed(() => store.state.Auth.nativeData);
    var showLoader = computed(() => store.state.Api.loading);
    var locale = computed(() => store.state.Auth.locale);
    var appTheme = computed(() => store.state.Auth.nativeData.themeMode);
    var authToken = computed(() => store.state.Auth.authToken);
    var entry = ref(store.state.Settings.entry);
    var calendar = computed(() => store.state.Auth.calendar);

    // This authData and authentificate of it below are necessary for entry in browser (for developing)
    var authData = {
      uid: '734656345683485',
      firebase_token: 'aaaaaaaaaaaaaa',
      theme_mode: 'dark',
      lang: 'en',
      google_id_token: '',
      google_access_token: '',
      platform: 'ios',
      apple_user_id: '',
      agreement: false
    };
    store.dispatch('Auth/authenticate', authData);
    console.log('app setting', JSON.stringify(nativeData.value));
    onBeforeMount(() => {
      document.body.classList.add('dark'); //can be removed, couse
    });

    appBridge.methods.nativeWaitForEvent('authToken', authData => {
      store.dispatch('Auth/authenticate', authData);
      nativeData.value = authData;
      store.dispatch('Auth/newGoogleAuth');
      console.log('native set auth', JSON.stringify(nativeData.value));
      // if(store.dispatch("Auth/authenticate", authData)) {
      //   appBridge.methods.nativeSendEvent("preloadInterstitial");
      // }
      // if (nativeData.google_id_token.length > 0 || nativeData.google_access_token.length > 0 || nativeData.apple_user_id.length > 0) {
      //   appBridge.methods.nativeSendEvent("preloadInterstitial");
      // }
    });

    appBridge.methods.nativeWaitForEvent('authTokenListen', authData => {
      store.dispatch('Auth/authenticate', authData);
      nativeData.value = authData;
      store.dispatch('Auth/newGoogleAuth');
      console.log('native listen auth', JSON.stringify(nativeData.value));
    });

    // appBridge.methods.nativeWaitForEvent('backButton', () => {
    // 	if (route.name === 'HomeView') {
    // 		appBridge.methods.nativeSendEvent('setPageLevel', { level: 0 });
    // 	} else {
    // 		appBridge.methods.nativeSendEvent('setPageLevel', { level: 1 });
    // 		goBack.methods.goBack();
    // 	}
    // });

    // appBridge.methods.nativeWaitForEvent('deepLink', deepLinkData => {
    // 	router.push(deepLinkData);
    // });

    watch(authToken, () => {
      console.log('watch auth');
      store.dispatch('Settings/getEntry');
      store.dispatch('Auth/getQuestions', locale.value);
    });
    watch(locale, (newLocale, oldLocale) => {
      console.log('watch locale');
      i18n.locale.value = newLocale;
      store.dispatch('Auth/getQuestions', newLocale);
    });
    watch(nativeData, () => {
      console.log('watch native');
      console.log(entry.value);
      // if (nativeData.value && entry.value) {
      // 	router.push({ name: 'QuestionsView' });
      // } else if (nativeData.value && !nativeData.value.google_access_token) {
      // 	router.push({ name: 'AuthorizationView' });
      // } else {
      // 	router.push({ name: 'HomeView' });
      // }
    });

    async function clearForGoogleAuth({
      commit
    }) {
      await commit('Auth/SET_NATIVE_DATA', null);
    }
    return (_ctx, _cache) => {
      var _component_router_view = _resolveComponent("router-view");
      var _component_van_col = _resolveComponent("van-col");
      var _component_van_row = _resolveComponent("van-row");
      return _openBlock(), _createBlock(_component_van_row, {
        class: "h-full bg-no-repeat bg-clip-border bg-origin-border bg-center bg-cover",
        justify: "center"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_col, {
          span: "24",
          class: "h-full"
        }, {
          default: _withCtx(() => [_createVNode(_unref(ItserveLoader), {
            show: showLoader.value
          }, null, 8, ["show"]), !nativeData.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Loading")) : (_openBlock(), _createBlock(_component_router_view, {
            key: 1
          }))]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};