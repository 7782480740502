export default {
  name: 'itserve-button',
  props: {
    type: String,
    value: String,
    nativeType: String,
    icon: Boolean,
    size: String,
    to: String,
    class: String
  },
  methods: {
    itserveButtonClicked() {
      this.nativeSendEvent('bottomBar', {});
    }
  }
};