import settings from '@/plugins/api/factories/Settings';

export const state = {
	settings: null,
	entry: 0,
	agreement: false,
};

export const mutations = {
	STORE_SETTINGS(state, settings) {
		state.settings = settings;
	},
	UPDATE_LANGUAGE(state, language) {
		if (state.settings !== null) {
			state.settings.language = language;
		}
	},
	SET_ENTRY(state, entry) {
		state.entry = entry.first_entry;
	},
	SET_AGREEMENT(state, agreement) {
		state.agreement = agreement;
	},
};

export const actions = {
	async getSettings({ commit }) {
		settings.getSettings(response => {
			commit('STORE_SETTINGS', response.user);
		});
	},
	async storeSettings({ state }) {
		return await settings.storeSettings(state.settings);
	},
	async putLanguage({ commit }, language) {
		commit('UPDATE_LANGUAGE', language);
		commit('Auth/SET_LOCALE', language, { root: true });
		return await settings.putLanguage(language);
	},
	setAgreement({ commit }, agreement) {
		return commit('SET_AGREEMENT', agreement);
	},
	getEntry({ commit }) {
		return settings.getEntry(response => {
			return commit('SET_ENTRY', response);
		});
	},
	putEntry({ commit }, first_entry) {
		commit('SET_ENTRY', { first_entry });
		return settings.putEntry({ first_entry });
	},
};
