import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "px-4 flex flex-col items-center h-full"
};
var _hoisted_2 = {
  class: "flex-1 pt-12"
};
var _hoisted_3 = {
  class: "mt-20 text-5xl font-bold text-decor text-rotate text-product-color-pink-400"
};
var _hoisted_4 = {
  class: "w-full text-center flex-1"
};
var _hoisted_5 = {
  class: "flex flex-col items-center text-center mb-15 flex-1"
};
var _hoisted_6 = {
  class: "inline-block pb-1"
};
import InfoMessage from '@/components/content/InfoMessage';
import AuthorizationButtons from '@/components/template/AuthorizationButtons.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'AuthorizationView',
  setup(__props) {
    var store = useStore();
    var entry = computed(() => store.state.Settings.entry);
    function onClick() {
      store.dispatch('Auth/changeGuest', true);
    }
    return (_ctx, _cache) => {
      var _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('messages.greeting')), 1)]), _createElementVNode("div", _hoisted_4, [_createVNode(_unref(InfoMessage), {
        direction: "vertical",
        class: "text-decor"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('authorization.title')), 1)]),
        _: 1
      }), _createVNode(AuthorizationButtons)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_router_link, {
        class: "flex items-center cursor-pointer font-medium text-2xl transition-colors hover:text-product-color-pink-600",
        to: {
          name: entry.value == 0 ? 'HomeView' : 'QuestionsView'
        },
        onClick: onClick
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('buttons.skip')), 1)]),
        _: 1
      }, 8, ["to"])])]);
    };
  }
};