import Account from '@/plugins/api/factories/Account';
import Settings from '@/plugins/api/factories/Settings';
import appBridge from '@/mixins/app-bridge';

export const state = {
	loading: false,
	guest: false,
	nativeData: null,
	authToken: null,
	locale: process.env.VUE_APP_I18N_DEFAULT_LOCALE,
	answers: {
		name: '',
		birth_date: formatter(new Date()),
		duration_period_last: 5,
		duration_cycle_last: 28,
		last_period_date: formatter(new Date()),
	},
	questions: {},
	calendar: [],
	notes: {
		date: null,
		sexual_act_protected: null,
		sexual_act_unprotected: null,
		orgasm: null,
		pills: null,
		notes: null,
		symptoms: null,
		temperature: null,
		weight: null,
		water: 0,
		mood: null,
	},
	notesArr: [],
};

export const mutations = {
	CHANGE_LOADING(state, bool) {
		state.loading = bool;
	},
	SET_AUTH_TOKEN(state, data) {
		state.authToken = data.data.token;
	},
	SET_NATIVE_DATA(state, nativeData) {
		state.nativeData = nativeData;
	},
	SET_THEME(state, theme) {
		state.nativeData.theme_mode = theme;
	},
	SET_LOCALE(state, locale) {
		let locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
		if (locales.indexOf(locale) >= 0) {
			state.nativeData.lang = locale;
		} else {
			state.nativeData.lang = process.env.VUE_APP_I18N_DEFAULT_LOCALE;
		}
	},
	SET_NATIVE_DATA_AGREEMENT(state, agreement) {
		state.nativeData.agreement = agreement;
	},
	SET_QUESTIONS(state, questions) {
		state.questions = questions.data;
	},
	SET_ANSWERS(state, answers) {
		state.answers = answers;
	},
	SET_CALENDAR(state, calendar) {
		state.calendar = calendar;
	},
	SET_GUEST(state, guest) {
		state.guest = guest;
	},
	SET_ACTIVE_DAY(state, date) {
		state.notes.date = date;
	},
	SET_SEXUAL_OPTIONS(state, sexualOptions) {
		state.notes.sexual_act_protected = sexualOptions.sexual_act_protected;
		state.notes.sexual_act_unprotected = sexualOptions.sexual_act_unprotected;
		state.notes.orgasm = sexualOptions.orgasm;
	},
	SET_PILLS_TEXT(state, pillsText) {
		state.notes.pills = pillsText;
	},
	SET_TEMPERATURE(state, temperature) {
		state.notes.temperature = temperature;
	},
	SET_NOTE_TEXT(state, noteText) {
		state.notes.notes = noteText;
	},
	SET_WEIGHT(state, weight) {
		state.notes.weight = weight;
	},
	SET_SYMPTOMS_TEXT(state, symptomsText) {
		state.notes.symptoms = symptomsText;
	},
	SET_WATER(state, water) {
		state.notes.water = water;
	},
	SET_MOOD(state, mood) {
		state.notes.mood = mood;
	},
	SET_NOTES_ARR(state, notesArr) {
		state.notesArr = notesArr;
	},
	SET_NOTES(state, notes) {
		state.notes = notes;
	},
};

export const actions = {
	authenticate({ dispatch, commit }, nativeData) {
		commit('CHANGE_LOADING', true);
		Account.nativeAuth(nativeData)
			.then(response => {
				commit('SET_AUTH_TOKEN', response.data);
				commit('SET_NATIVE_DATA', nativeData);
				commit('CHANGE_LOADING', false);
			})
			.then(res => {
				Settings.getEntry(response => {
					return commit('Settings/SET_ENTRY', response, { root: true });
				});
			});
	},
	async changeLanguage({ commit }, language) {
		commit('SET_LOCALE', language);
		await Settings.putLanguage(language);
	},
	async changeTheme({ commit }, theme) {
		appBridge.methods.nativeSendEvent('setThemeMode', theme);
		commit('SET_THEME', theme);
		await Settings.putTheme(theme);
	},
	async refreshToken({ commit }) {
		return Account.refreshToken(response => {
			commit('SET_AUTH_TOKEN', response.data);
		});
	},
	changeNativeDataAgreement({ commit }, agreement) {
		commit('SET_NATIVE_DATA_AGREEMENT', agreement);
	},
	changeGuest({ commit }, guest) {
		commit('SET_GUEST', guest);
	},
	newGoogleAuth({ state }, callBackFn) {
		Account.googleAuth(state.nativeData, res => {
			callBackFn(res);
		}).then(() => {
			this.$router.push({
				name: 'Account',
			});
		});
	},
	getQuestions({ commit }, language) {
		return language
			? Account.getQuestions(response => {
					return commit('SET_QUESTIONS', response);
			  }, language)
			: state.locale;
	},
	setAnswers({ dispatch, commit }, answers) {
		commit('Settings/SET_ENTRY', { first_entry: 0 }, { root: true });
		commit('SET_ANSWERS', answers);
		dispatch('setCalendar', answers);
		Account.setAnswers(answers);
		Settings.putEntry({ first_entry: 0 });
	},
	getAnswers({ commit }) {
		return Account.getAnswers(response => {
			return commit('SET_ANSWERS', response.data);
		});
	},
	updateAnswers({ commit }, answers) {
		commit('SET_ANSWERS', answers);
		dispatch('setCalendar', answers);
		return Account.updateAnswers(answers);
	},
	getCalendar({ commit }) {
		return Account.getCalendar(response => {
			return commit('SET_CALENDAR', response.data);
		});
	},
	setCalendar({ commit }, answers) {
		let calendar = [];
		let day = 24 * 3600 * 1000;
		for (let i = 0; i < 12; i++) {
			let firstDate =
				new Date(answers.last_period_date).getTime() +
				i * answers.duration_cycle_last * day;
			for (let j = 0; j < answers.duration_period_last; j++) {
				let nextDate = firstDate + j * day;
				calendar.push({
					period_date: formatter(new Date(nextDate)),
				});
			}
		}
		commit('SET_CALENDAR', calendar);
	},
	updateCalendar({ commit }, calendar) {
		commit('SET_CALENDAR', calendar);
		Account.updateCalendar(calendar);
	},
	setActiveDay({ commit }, activeDay) {
		commit('SET_ACTIVE_DAY', activeDay);
	},
	setSexualOptions({ commit }, sexualOptions) {
		commit('SET_SEXUAL_OPTIONS', sexualOptions);
	},
	setPillsText({ commit }, pillsText) {
		commit('SET_PILLS_TEXT', pillsText);
	},
	setTemperature({ commit }, temperature) {
		commit('SET_TEMPERATURE', temperature);
	},
	setNoteText({ commit }, noteText) {
		commit('SET_NOTE_TEXT', noteText);
	},
	setWeight({ commit }, weight) {
		commit('SET_WEIGHT', weight);
	},
	setSymptomsText({ commit }, symptomsText) {
		commit('SET_SYMPTOMS_TEXT', symptomsText);
	},
	setWater({ commit }, water) {
		commit('SET_WATER', water);
	},
	setMood({ commit }, mood) {
		commit('SET_MOOD', mood);
	},
	setNotesArr({ commit }, notesArr) {
		commit('SET_NOTES_ARR', notesArr);
		Account.setNotesArr(notesArr);
	},
	getNotesArr({ commit }) {
		return Account.getNotesArr(response => {
			return commit('SET_NOTES_ARR', response.data);
		});
	},
	setNotes({ commit }, notes) {
		commit('SET_NOTES', notes);
	},
};

function formatter(date) {
	return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
		date.getDate()
	)}`;
}
function addZero(num) {
	return num < 9 ? `0${num}` : num;
}
