import api from '@/plugins/api/EntryPoint';

export default {
	getLanguage(callBackFn) {
		return api.get(`settings/language`, callBackFn);
	},
	putLanguage(language) {
		return api.put('settings/put-language', { language });
	},
	getTheme(callBackFn) {
		return api.get(`settings/theme`, callBackFn);
	},
	putTheme(theme) {
		return api.put('settings/set-theme', { theme });
	},
	getEntry(callBackFn) {
		return api.get(`settings/entry`, callBackFn);
	},
	putEntry(first_entry) {
		return api.put('settings/put-entry', first_entry);
	},
};
