import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  key: 2
};
var _hoisted_4 = {
  key: 3
};
var _hoisted_5 = {
  key: 4
};
var _hoisted_6 = {
  key: 5,
  class: "max-w-xs van-ellipsis"
};
var _hoisted_7 = {
  key: 6,
  class: "max-w-xs van-ellipsis"
};
var _hoisted_8 = {
  key: 7,
  class: "max-w-xs van-ellipsis"
};
export default {
  __name: 'NotesList',
  props: {
    note: Object
  },
  setup(__props) {
    var {
      note
    } = __props;
    return (_ctx, _cache) => {
      var _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
      var _component_van_space = _resolveComponent("van-space");
      return _openBlock(), _createBlock(_component_van_space, {
        wrap: "",
        class: "w-full mt-3 text-sm"
      }, {
        default: _withCtx(() => [__props.note.sexual_act_protected || __props.note.sexual_act_unprotected ? (_openBlock(), _createElementBlock("p", _hoisted_1, [_createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-heart",
          class: "text-red-600"
        }), _createTextVNode(" " + _toDisplayString(__props.note.sexual_act_protected && _ctx.$t('SexualActView.condom') || __props.note.sexual_act_unprotected && _ctx.$t('SexualActView.no_condom')), 1)])) : _createCommentVNode("", true), __props.note.weight ? (_openBlock(), _createElementBlock("p", _hoisted_2, [_createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-weight-scale",
          class: "text-blue-600"
        }), _createTextVNode(" " + _toDisplayString(__props.note.weight), 1)])) : _createCommentVNode("", true), __props.note.mood ? (_openBlock(), _createElementBlock("p", _hoisted_3, [_createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-face-smile",
          class: "text-yellow-500"
        }), _createTextVNode(" " + _toDisplayString(__props.note.mood == 2 && 'Good' || __props.note.mood == 1 && 'Straight' || __props.note.mood == 0 && 'Bad'), 1)])) : _createCommentVNode("", true), __props.note.temperature ? (_openBlock(), _createElementBlock("p", _hoisted_4, [_createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-thermometer",
          class: "text-gray-500"
        }), _createTextVNode(" " + _toDisplayString(__props.note.temperature), 1)])) : _createCommentVNode("", true), __props.note.water ? (_openBlock(), _createElementBlock("p", _hoisted_5, [_createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-glass-water",
          class: "text-blue-600"
        }), _createTextVNode(" " + _toDisplayString(__props.note.water), 1)])) : _createCommentVNode("", true), __props.note.pills ? (_openBlock(), _createElementBlock("p", _hoisted_6, [_createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-pills",
          class: "text-gray-500"
        }), _createTextVNode(" " + _toDisplayString(__props.note.pills), 1)])) : _createCommentVNode("", true), __props.note.notes ? (_openBlock(), _createElementBlock("p", _hoisted_7, [_createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-note-sticky",
          class: "text-yellow-500"
        }), _createTextVNode(" " + _toDisplayString(__props.note.notes), 1)])) : _createCommentVNode("", true), __props.note.symptoms ? (_openBlock(), _createElementBlock("p", _hoisted_8, [_createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-kit-medical",
          class: "text-red-600"
        }), _createTextVNode(" " + _toDisplayString(__props.note.symptoms), 1)])) : _createCommentVNode("", true)]),
        _: 1
      });
    };
  }
};