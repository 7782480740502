import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref } from 'vue';
import { onMounted } from 'vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'CheckBoxIOS',
  setup(__props) {
    var store = useStore();
    var theme = computed(() => store.state.Auth.nativeData.theme_mode);
    var checked = ref(false);
    onMounted(() => {
      if (theme.value === 'dark') {
        checked.value = true;
      }
    });
    function changeTheme() {
      checked.value ? store.dispatch('Auth/changeTheme', 'dark') : store.dispatch('Auth/changeTheme', 'light');
    }
    return (_ctx, _cache) => {
      var _component_van_switch = _resolveComponent("van-switch");
      var _component_van_cell = _resolveComponent("van-cell");
      return _openBlock(), _createBlock(_component_van_cell, {
        center: "",
        title: checked.value ? _ctx.$t('settings.theme.dark') : _ctx.$t('settings.theme.light')
      }, {
        "right-icon": _withCtx(() => [_createVNode(_component_van_switch, {
          modelValue: checked.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => checked.value = $event),
          onChange: changeTheme
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["title"]);
    };
  }
};