import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_button = _resolveComponent("van-button");
  return _openBlock(), _createBlock(_component_van_button, {
    type: $props.type,
    "native-type": $props.nativeType,
    size: $props.size,
    to: $props.to,
    onClick: $options.itserveButtonClicked,
    class: "sm:max-w-xs text-xl text-black font-medium",
    color: "linear-gradient(to right, #E84762, #F4F474)"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "icon"), _createTextVNode(" " + _toDisplayString($props.value), 1)]),
    _: 3
  }, 8, ["type", "native-type", "size", "to", "onClick"]);
}