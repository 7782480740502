import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  var _component_itserve_button = _resolveComponent("itserve-button");
  var _component_van_space = _resolveComponent("van-space");
  return _openBlock(), _createBlock(_component_van_space, {
    direction: "vertical",
    class: "w-full"
  }, {
    default: _withCtx(() => [_createVNode(_component_itserve_button, {
      value: "Google",
      type: "primary",
      size: "large",
      color: "#1d1d1d",
      plain: "",
      onClick: $options.googleSign
    }, {
      icon: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
        icon: "fa-brands fa-google"
      })]),
      _: 1
    }, 8, ["onClick"]), $options.nativeData.platform === 'ios' ? (_openBlock(), _createBlock(_component_itserve_button, {
      key: 0,
      value: "Apple",
      type: "primary",
      size: "large",
      color: "#1d1d1d",
      onClick: $options.appleSign
    }, {
      icon: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
        icon: "fa-brands fa-apple",
        size: "lg",
        class: "mr-1"
      })]),
      _: 1
    }, 8, ["onClick"])) : _createCommentVNode("", true)]),
    _: 1
  });
}