import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["src"];
export default {
  __name: 'BasicIframe',
  props: {
    url: String
  },
  setup(__props) {
    var {
      url
    } = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("iframe", {
        src: __props.url,
        class: "bg-white right-0 fixed w-full h-full border-none m-0 p-0"
      }, null, 8, _hoisted_1);
    };
  }
};