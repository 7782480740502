import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'ItserveLanguagePicker',
  setup(__props) {
    var store = useStore();
    var showPicker = ref(false);
    var locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',').map(elem => {
      return {
        text: elem,
        value: elem
      };
    });
    var selectedLocale = ref(store.state.Auth.nativeData.lang);
    function onConfirm({
      selectedOptions
    }) {
      selectedLocale.value = selectedOptions[0].text;
      showPicker.value = false;
      store.dispatch('Auth/changeLanguage', selectedLocale.value);
    }
    return (_ctx, _cache) => {
      var _component_van_field = _resolveComponent("van-field");
      var _component_van_picker = _resolveComponent("van-picker");
      var _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_field, {
        modelValue: selectedLocale.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => selectedLocale.value = $event),
        center: "",
        isLink: "",
        readonly: "",
        label: _ctx.$t('settings.components.picker.lang'),
        onClick: _cache[1] || (_cache[1] = $event => showPicker.value = true)
      }, null, 8, ["modelValue", "label"]), _createVNode(_component_van_popup, {
        show: showPicker.value,
        "onUpdate:show": _cache[3] || (_cache[3] = $event => showPicker.value = $event),
        round: "",
        position: "bottom"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_picker, {
          columns: _unref(locales),
          onCancel: _cache[2] || (_cache[2] = $event => showPicker.value = false),
          onConfirm: onConfirm
        }, null, 8, ["columns"])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};